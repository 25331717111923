import React, { useState } from 'react';
import './InputModal.css';
import useKeypress from '../../hooks/useKeypress';
import LocationSearchInput from '../LocationSearchInput/LocationSearchInput';
import DateSelectionInput from '../DateSelectionInput/DateSelectionInput';
import Button from '../Button/Button';
import { Link } from 'react-router-dom';
import {
    useQueryParam,
    StringParam,
    NumberParam,
    DelimitedArrayParam,
} from 'use-query-params';

function InputModal(props) {
    /* --- Overview ---
    This InputModal will render what components are needed based on the state of the URL query parameters
    You can override what inputs appear by including them in props.inputs = [LocationSearchInput, DateSelectionInput, ...]
    */

    // From Props. If inputs prop is specified, show only the inputs listed, else show all applicable based on query param states
    const showLocationSearchInput = props.inputs === undefined || props.inputs.includes('LocationSearchInput');
    const showOnlyLocationSearchInput = props.inputs !== undefined && props.inputs.includes('LocationSearchInput');
    const showDateSelectionInput = props.inputs === undefined || props.inputs.includes('DateSelectionInput');
    const closeDialog = props.closeDialog;

    // State
    const [locationSearchInputIsActive, setLocationSearchInputIsActive] = useState(true);

    // Query params storing states
    const [campground, setCampground] = useQueryParam('id', StringParam); // This is used only for FacilityPage
    const [placeLabel, setPlaceLabel] = useQueryParam('place', StringParam);
    const [latitude, setLatitude] = useQueryParam('latitude', NumberParam);
    const [longitude, setLongitude] = useQueryParam('longitude', NumberParam);
    const [userDefinedDateRanges, setUserDefinedDateRanges] = useQueryParam('dates', DelimitedArrayParam);
    const [consecutiveDays, setConsecutiveDays] = useQueryParam('nights', NumberParam);
    const [weekendDays, setWeekendDays] = useQueryParam('weekenddays', NumberParam);
    const [monthsOfYear, setMonthsOfYear] = useQueryParam('monthsofyear', DelimitedArrayParam);
    const [nextMonths, setNextMonths] = useQueryParam('nextmonths', NumberParam);
    const [equipment, setEquipment] = useQueryParam('equipment', DelimitedArrayParam);
    const [experience, setExperience] = useQueryParam('experience', StringParam); // This might change to "activities" 

    // Generalize the state of the location input
    const hasCampground = campground !== undefined && campground !== null;
    const hasPlaceLabel = placeLabel !== undefined && placeLabel !== null;
    const hasLatitudeLongitude = latitude !== undefined && latitude !== null && typeof latitude === 'number' && longitude !== undefined && longitude !== null && typeof longitude === 'number';

    // Generalize the state of date inputs
    const userDefinedDateRangesIsEmpty = (userDefinedDateRanges === undefined || userDefinedDateRanges === null || userDefinedDateRanges.length === 0);
    const monthsOfYearIsEmpty = (monthsOfYear === undefined || monthsOfYear === null || monthsOfYear.length === 0);
    const nextMonthsIsEmpty = (nextMonths === undefined || nextMonths === null);
    const flexDateConstraintsIsEmpty = monthsOfYearIsEmpty && nextMonthsIsEmpty;
    const consecutiveDaysIsEmpty = (consecutiveDays === undefined || consecutiveDays === null);
    const weekendDaysIsEmpty = (weekendDays === undefined || weekendDays === null);


    const handleCloseDialog = (e) => {
        e.preventDefault();
        props.closeDialog()
    }

    // Conditionally render location input section if requested or if props.inputs is undefined
    let locationInputSection;
    if (showLocationSearchInput) {
        locationInputSection = (
            <div className='InputModal-location-section'>
                <LocationSearchInput
                    locationSearchInputIsActive={locationSearchInputIsActive}
                    setLocationSearchInputIsActive={setLocationSearchInputIsActive}
                    showOnlyLocationSearchInput={showOnlyLocationSearchInput}
                    closeDialog={closeDialog}
                />
            </div>
        )
    }

    // Conditionally render the date inputs after a location is selected and the location search is not active
    let dateInputSection;
    if (showDateSelectionInput && !locationSearchInputIsActive && (hasCampground || hasLatitudeLongitude)) {
        dateInputSection = (
            <div className='InputModal-date-section'>
                <DateSelectionInput />
            </div>
        )
    }

    // Provides the current query params as a string when navigating to another page
    const queryString = window.location.search;

    // Conditionally render the search button to submit the search/update
    let searchButton;
    const buttonType = (!userDefinedDateRangesIsEmpty || !flexDateConstraintsIsEmpty) ? 'primary' : 'tertiary';
    const buttonText = (!userDefinedDateRangesIsEmpty || !flexDateConstraintsIsEmpty) ? 'Search' : 'Next Available'
    if (!locationSearchInputIsActive && hasCampground) { // TODO: restrict to only when dates are specify. Add && (!userDefinedDateRangesIsEmpty || !flexDateConstraintsIsEmpty)
        searchButton = (
            <div className='InputModal-search-button-container'>
                <Link to={{
                    pathname: '/',
                    search: queryString + '&showmap=1', // For mobile, need the  map to load before the list
                    key: queryString, // This is to trick it to re-render the page/component if already on it, though some say Math.random doesn't guarantee uniqueness
                    state: {
                        applied: true
                    }
                }} >
                    <Button type={buttonType} fullWidth>{buttonText}</Button>
                </Link>
            </div>
        )
    }
    else if (!locationSearchInputIsActive && hasLatitudeLongitude) { // TODO: restrict to only when dates are specify. Add && (!userDefinedDateRangesIsEmpty || !flexDateConstraintsIsEmpty)
        searchButton = (
            <div className='InputModal-search-button-container'>
                <Link to={{
                    pathname: '/search',
                    search: queryString + '&showmap=1',
                    key: queryString, // This is to trick it to re-render the page/component if already on it, though some say Math.random doesn't guarantee uniqueness
                    state: {
                        applied: true
                    }
                }} >
                    <Button type={buttonType} fullWidth>{buttonText}</Button>
                </Link>
            </div>
        )
    }


    useKeypress('Escape', () => {
        props.closeDialog();
    });

    return (
        <div className='InputModal'>
            {/* <button onClick={handleCloseDialog}>X</button> */}
            <div className='InputModal-contents'>
                {locationInputSection}
                {dateInputSection}
                <div className='InputModal-search-section'>
                    {searchButton}
                </div>
            </div>
        </div>
    )
}

export default InputModal;