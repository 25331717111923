import React, { useState } from 'react';
import './FacilitiesSearchHeader.css';
import LocationInputButton from '../LocationInputButton/LocationInputButton';
import DateChangeInputButton from '../DateChangeInputButton/DateChangeInputButton';
import FilterChangeInputButton from '../FilterChangeInputButton/FilterChangeInputButton';
import Button from '../Button/Button';
import { Link } from 'react-router-dom';
import {
    useQueryParam,
    StringParam,
    NumberParam,
    DelimitedArrayParam,
    BooleanParam,
} from 'use-query-params';

function FacilitiesSearchHeader(props) {
    // Access parameter states
    const [campground, setCampground] = useQueryParam('id', StringParam); // This is used only for FacilityPage
    const [placeLabel, setPlaceLabel] = useQueryParam('place', StringParam);
    const [latitude, setLatitude] = useQueryParam('latitude', NumberParam);
    const [longitude, setLongitude] = useQueryParam('longitude', NumberParam);

    // Mobile map parameter to toggling map and list on mobile
    const [showMap, setShowMap] = useQueryParam('showmap', BooleanParam);

    // Generalize the state of the location input
    const hasCampground = campground !== undefined && campground !== null;
    const hasPlaceLabel = placeLabel !== undefined && placeLabel !== null;
    const hasLatitudeLongitude = latitude !== undefined && latitude !== null && typeof latitude === 'number' && longitude !== undefined && longitude !== null && typeof longitude === 'number';

    // Handle for adding URL param to show map
    function handleToggleShowMap(e) {
        if (showMap) {
            setShowMap(!showMap);
            // Also update the list results from map
            props.handleUpdateFacilityListItems();
        }
        else {
            setShowMap(true);
        }
    }

    return (
        <div className='FacilitiesSearchHeader'>
            <div className='FacilitiesSearchHeader-section1'>
                <div className='FacilitiesSearchHeader-section1-logo'>
                    <Link to='' >
                        {/* <div className='FacilitiesSearchHeader-section1-logo-text'>CS</div> */}
                        <img src="logo192.png"></img>
                    </Link>
                </div>
                <div className='FacilitiesSearchHeader-section1-location'>
                    <LocationInputButton inputs={['LocationSearchInput']} />
                </div>
            </div>
            <div className='FacilitiesSearchHeader-section2'>
                {/* <Button type='secondary' size='sm'>Change dates</Button> */}
                <DateChangeInputButton />
                <FilterChangeInputButton />
                <div className='FacilitiesSearchHeader-view-map-btn'>
                    <Button type='tertiary' size='sm' onClick={handleToggleShowMap}>{showMap ? 'View List' : 'View Map'}</Button>
                </div>
            </div>
            <div className='FacilitiesSearchHeader-section3'>
                <Button type='tertiary' size='sm'>
                    {/* <a href="mailto:feedback@campsitescout?subject=Feedback for campsitescout&body=How was this site helpful and what would you like to see improved?">Give Feedback</a> */}
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScJDsTtWbAG7UJT2QZUe-9g0bdiEa50MSdlwGLAFs5EfKAduQ/viewform?usp=dialog" target="_blank">Give Feedback</a>
                </Button>
            </div>

        </div>
    )
}

export default FacilitiesSearchHeader